<template>
    <div :class="setSectionStyling(data.background_color, is_first_section)" data-section-type="hero-product-slider">
        <hero-product-slider :data="data" :cards="cards" :is_first_heading_tag="is_first_heading_tag"/>
    </div>
</template>
<script>
    import HeroProductSlider from '../Components/HeroProductSlider.vue';
    import { usePage } from '@inertiajs/vue3';


    export default {
        props: {
            data : Object,
            is_first_heading_tag : Boolean
        },
        components: {
            'hero-product-slider' : HeroProductSlider
        },
        data: () => {
            return {
                cards: [],
                is_loading: false,
            }
        },
        mounted: function() {
            this.getProducts();
        },
        methods: {
            /**
             * Get the products for this sections
             *
             * @return void
             *
             * @author Joost Ligthart <joost@click.nl>
             */
            getProducts: function() {
                this.is_loading = true;
                axios.get(`/${usePage().props.locale}/api/card-overview/${this.data.id}/get-products`)
                    .then((response) => {
                        this.cards = response.data
                        this.is_loading = false;
                    });
            }
        }
    }
</script>