<template>
    <section :class="setSectionStyling(data.background_color, is_first_section)" class="c-swiper-slider u-overflow-x-hidden" type="section-card-slider">
        <div class="o-row u-margin-bottom-small">
            <div class="o-col o-col--xs-12 o-flex">
                <div class="o-flex--auto">
                    <h1 v-if="data.title && is_first_heading_tag" class="u-no-margin u-break-word" :class="['c-heading--medium', 'u-color-black']">{{ data.title }}</h1>
                    <h2 v-if="data.title && !is_first_heading_tag" class="u-no-margin u-break-word" :class="['c-heading--medium', 'u-color-black']">{{ data.title }}</h2>
                </div>
                <div v-if="data.translated_first_button && data.translated_first_button.button_text && data.translated_first_button.button_url" class="o-flex--default">
                    <template v-if="data.translated_first_button.is_button_target_blank === '1'">
                        <a :href="data.translated_first_button.button_url" target="_blank" rel="noopener" class="c-button c-button--clear">
                            <span class="u-show-for-md">{{ data.translated_first_button.button_text }}</span> <arrow-right :width="18" :height="18" :classes="'u-fill-tertiary'"></arrow-right>
                        </a>
                    </template>
                    <template v-else>
                        <inertia-link :href="data.translated_first_button.button_url" :class="'c-button c-button--clear'">
                            <span class="u-show-for-md">{{ data.translated_first_button.button_text }}</span> <arrow-right :width="18" :height="18" :classes="'u-fill-tertiary'"></arrow-right>
                        </inertia-link>
                    </template>
                </div>
            </div>
        </div>
        <card-slider :cards="cards" :is_loading="is_loading"></card-slider>
    </section>
</template>

<script>
    import ArrowRight from '../Components/Icons/ArrowRight';
    import CardSlider from '../Components/CardSlider.vue';
    import { usePage, Link } from '@inertiajs/vue3';

    export default {
        props: {
            data : Object,
            is_first_section: Boolean,
            is_first_heading_tag: Boolean
        },
        components: {
            'card-slider' : CardSlider,
            'arrow-right' : ArrowRight,
            'inertia-link' : Link
        },
        data: () => {
            return {
                cards: [],
                is_loading: false,
            }
        },
        mounted: function() {
            this.getProducts();
        },
        methods: {
            /**
             * Get the products for this sections
             *
             * @return void
             *
             * @author Joost Ligthart <joost@click.nl>
             */
            getProducts: function() {
                this.is_loading = true;
                axios.get(`/${usePage().props.locale}/api/card-overview/${this.data.id}/get-products`)
                    .then((response) => {
                        this.cards = response.data
                        this.is_loading = false;
                    });
            }
        }
    }
</script>